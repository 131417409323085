import React, { useState, useEffect, useRef } from "react";
import Navbar3 from "./Navbar3";
import "./About.css";
import { CarouselData } from "./CarouselData";
import background1 from "../img/coffee-beans1.png";
import { motion } from "framer-motion";
import up from "../coffeeflavour/vanilla.png";
import down from "../coffeeflavour/espresso.png";
import down2 from "../coffeeflavour/raspberry.png";
import "./Products.css";
import Card from "./Cards/Card";
import Card2 from "./Cards/Card2";
import Card3 from "./Cards/Card3";
import Card4 from "./Cards/Card4";
import Footer from "./Footer";
import { db } from "./Firebase/Firebase";
import { Link, useNavigate } from "react-router-dom";
import Loader from "./Loader";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { BiRightArrowAlt } from "react-icons/bi";
const Products = () => {
  // const { id } = useParams(); 
  const [product, setProduct] = useState({});
  const Name = product.id;
  const transition = { type: "spring", duration: 3 };
  const [selected, setSelected] = useState(0);
  const tLength = CarouselData.length;
  const navigate = useNavigate();

  const [Products, setProducts] = useState();
  useEffect(() => {
    const todosref = collection(db, "Products");
    const q = query(todosref);
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Products = [];
      querySnapshot.forEach((doc) => {
        Products.push({
          id: doc.id,
          ...doc.data(),
        });
        setProducts(Products);
      });
    });

    return () => unsub();
  }, []);
  const [count, setCount] = useState('0')
  useEffect(() => {
    const timeout = setTimeout(()=> {
      setCount('1')
    }, 2000)
  
    return () => clearTimeout(timeout)
  }, [count])
  

  return (
    <>
{count === "0" ? (
  <>
  <Loader/>
  </>
): (
  <>
 <div className="fontss">
  

  <Navbar3 />
  <body>
    <div style={{ padding: "0", margin: "0" }}></div>
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Montserrat:400,700"
    />

    <section>
      <div
        id="parallax"
        class="parallax-item text1"
        style={{ marginTop: "-299px" }}
      >
        {/* <h2
        className="anti"
        
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          Antidote
        </h2> */}
      </div>
      <div>
        <section
          className="container77"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          {/* <img
        src={require("../img/bg1.png")}
        alt=""
        style={{ width: "100%" }}
      /> */}
          <div
            style={{ lineHeight: "2px", width: "100%", marginTop: "147px" }}
          >
            <h1
              className="font3 font789"
              data-aos-duration="1500"
              data-aos="zoom-in-up"
              style={{
                textAlign: "center",
                color: "white",
                letterSpacing: "8px",
                fontSize: "76px",
                color: "#C7A17A",
              }}
            >
              our
            </h1>

            <h2
              className="fontss"
              style={{
                fontSize: "50px",
                letterSpacing: "10px",
                textAlign: "center",
                color: "white",
              }}
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              Products
            </h2>
          </div>

          <img
            className="bg-img"
            src={require("../img/bg2.png")}
            alt=""
            style={{ width: "100%" }}
          />
        </section>
      </div>

      <div class="parallax-item">
        <div className="fontss">
          <div
            className="aboutbg1"
            //  style={{backgroundColor:'black'}}
          >
            <div className="nav" style={{ backgroundColor: "black" }}></div>
            <div
              className="aboutflex"
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <div className="image-coff" style={{ width: "15%" }}>
                <img
                  src={require("../img/coffee-beans1.png")}
                  alt=""
                  className="beans"
                  style={{ width: "163%" }}
                />
              </div>
              <div
                className="yt"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "140%",
                  justifyContent: "center",
                }}
              >
                <div className="left-hh font78" style={{ width: "59%" }}>
                  <h1 className="heading8">
                    AntidotÉ
                    {/* <span>®</span> */}
                    <br />
                    <span>
                      {CarouselData[selected].name === "Irish" ? (
                        <span style={{ color: "#f6c38f" }}>
                          {CarouselData[selected].name}
                        </span>
                      ) : (
                        <>
                          {CarouselData[selected].name === "eSpresso" ? (
                            <span style={{ color: "#754C29" }}>
                              {CarouselData[selected].name}
                            </span>
                          ) : (
                            <>
                              {CarouselData[selected].name ===
                              "Rasberry" ? (
                                <span style={{ color: "#714a63" }}>
                                  {CarouselData[selected].name}
                                </span>
                              ) : (
                                <span style={{ color: "#ddcf8c" }}>
                                  {CarouselData[selected].name}
                                </span>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </span>{" "}
                  </h1>

                  <div
                    className="font66"
                    style={{
                      fontSize: "22px",
                      fontWeight: "100",
                    }}
                  >
                    <motion.span
                      key={selected}
                      transition={transition}
                      initial={{ opacity: 0, x: 100 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -100 }}
                    >
                      {CarouselData[selected].review}

                      {/* {CarouselData[selected].status} */}
                    </motion.span>
                  </div>
       
                  <div
                    data-aos-duration="1000"
                    data-aos="zoom-in-right"
                    onClick={navigate('/Espresso')}
                    className="status"
                    style={{ cursor: "pointer" }}
                  >
         
              
           
                    <div className="status-svg"></div>
                    <p className="status88 hover">
                      {CarouselData[selected].status}
                    </p>

                    {CarouselData[selected].status ===
                    "Explore Antidote Irish" ? (
                      <BiRightArrowAlt
                        className="hover0 hover"
                        style={{
                          width: "42px",
                          // fontSize: "24px"
                        }}
                      />
                    ) : (
                      <>
                        {CarouselData[selected].status ===
                        "Explore Antidote Espresso" ? (
                          <BiRightArrowAlt
                            className="hover1 hover"
                            style={{
                              width: "42px",
                              fontSize: "24px",
                            }}
                          />
                        ) : (
                          <>
                            {CarouselData[selected].status ===
                            "Explore Antidote Rasberry" ? (
                              <BiRightArrowAlt
                                className="hover3 hover"
                                style={{
                                  width: "42px",
                                  fontSize: "24px",
                                }}
                              />
                            ) : (
                              <BiRightArrowAlt
                                className="hover2 hover"
                                style={{
                                  width: "42px",
                                  fontSize: "24px",
                                }}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="right-hh bggg " style={{ display: "flex" }}>
                <motion.img
                  //  data-aos-duration="2000"
                  key={selected}
                  transition={transition}
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -100 }}
                  className="imggg"
                  src={CarouselData[selected].image}
                  alt=""
                />
                <div
                  className="bgarrow  arrow bg56"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "30px",

                    marginBottom: "30px",
                  }}
                >
                  {/* <div></div> */}
                  <h1
                    data-aos="fade-left"
                    data-aos-duration="3000"
                    style={{
                      marginTop: "2px",
                      fontWeight: "100",
                      whiteSpace: "pre",
                    }}
                  >
                    Similar Flavours
                  </h1>
                  <div
                    className="boxxx"
                    style={{
                      width: "30%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                  >
                    <img
                      className="img0"
                      style={{ width: "90px", marginTop: "-20px" }}
                      onClick={() => {
                        selected === 0
                          ? setSelected(tLength - 1)
                          : setSelected((prev) => prev - 1);
                      }}
                      src={up}
                      alt=""
                    />
                  </div>

                  <div
                    className="boxxx"
                    style={{
                      width: "30%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    data-aos="fade-right"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                  >
                    <img
                      className="img0"
                      style={{ width: "90px" }}
                      onClick={() => {
                        selected === 0
                          ? setSelected(tLength - 1)
                          : setSelected((prev) => prev - 1);
                      }}
                      src={down}
                      alt=""
                    />
                  </div>
                  <div
                    className="boxxx"
                    style={{
                      width: "30%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                  >
                    <img
                      className="img0"
                      style={{ width: "90px" }}
                      onClick={() => {
                        selected === 0
                          ? setSelected(tLength - 1)
                          : setSelected((prev) => prev - 1);
                      }}
                      src={down2}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="flavour-heading">
                <h1 style={{ fontFamily: "Yesteryear" }}>
                  similar flavour
                </h1>
              </div>

              <div className="mediaQ">
                <img
                  data-aos="fade-left"
                  //  data-aos-easing="linear"
                  data-aos-duration="1000"
                  onClick={() => {
                    selected === 0
                      ? setSelected(tLength - 1)
                      : setSelected((prev) => prev - 1);
                  }}
                  src={up}
                  alt=""
                />

                <img
                  data-aos="fade-down"
                  // data-aos-easing="linear"
                  data-aos-duration="1500"
                  onClick={() => {
                    selected === 0
                      ? setSelected(tLength - 1)
                      : setSelected((prev) => prev - 1);
                  }}
                  src={down}
                  alt=""
                />
                <img
                  data-aos="fade-right"
                  // data-aos-easing="linear"
                  data-aos-duration="1000"
                  onClick={() => {
                    selected === 0
                      ? setSelected(tLength - 1)
                      : setSelected((prev) => prev - 1);
                  }}
                  src={down2}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="parallax-item">
        <div
          className="roww2"
          style={{
            display: "flex",
            gap: "2rem",
            marginTop: "50px",
            justifyContent: "center",
            margin: "30px",
          }}
        >
          <div>
            {" "}
            <img
              src={require("../img/flex1.jpg")}
              alt=""
              style={{ width: "517px" }}
              className="flex3"
            />{" "}
          </div>
          <div>
            {" "}
            <img
              src={require("../img/flex2.jpg")}
              style={{ width: "517px" }}
              className="flex4"
            />
          </div>
        </div>
        <div
          className="roww2"
          style={{
            display: "flex",
            gap: "2rem",
            marginTop: "50px",
            justifyContent: "center",
            margin: "30px",
          }}
        >
          <div>
            {" "}
            <img
              src={require("../img/flex3.jpg")}
              alt=""
              style={{ width: "517px" }}
              className="flex3"
            />{" "}
          </div>
          <div>
            {" "}
            <img
              src={require("../img/flex4.jpg")}
              style={{ width: "517px" }}
              className="flex4"
            />
          </div>
        </div>
      </div> */}
      {/* <div class="parallax-item"></div> */}
      <div
        class="parallax-item cards"
        style={{ display: "flex", justifyContent: "space-evenly" }}
      >
        {Products?.map((Product) => (
          <>
            <Card product={Product} />
          </>
        ))}
        {/* <Card2 />
        <Card3 />
        <Card4 /> */}
      </div>
      {/* <div
        className="cards2 parallax-item"
        style={{ display: "flex", justifyContent: "space-evenly" ,marginTop:'10px' }}
      >
        <Card3 />
        <Card4 />
      </div> */}
    </section>
  </body>

  {/* <section className="blue">


    <div className="fll" >
    <div className="st">
    <img src={require('../img2/pro1.jpg')} alt=""  style={{borderRadius: "14px"}}/>
   <h1 className="h11">Hand Picked</h1>
   <p className="p11">Our coffees are made from beans sourced from India’s finest estates, handpicked when they are in their ideal state of ripeness ensuring the best cup of coffee for you.

</p>

    </div>
    <div className="st" >
    <img src={require('../img2/pro2.jpg')} alt=""  style={{borderRadius: "14px"}}/>
<h1 className="h11">The Beans</h1>
<p className="p11">Only the choicest of beans make it to the selection of coffee we use to make our signature blends. We use Grade A and above beans for all our coffee.</p>

    </div>
    <div className="st" >
    <img  src={require('../img2/pro3.jpg')} alt=""  style={{borderRadius: "14px"}}/>
<h1 className="h11">Artisanal Coffee</h1>
<p className="p11"> Our in house barista oversees the entire operation from processing to roasting, grinding and packaging. </p>

    </div>
   
    </div>
  </section> */}
  <section style={{ marginTop: "153px" }}>
    <div>
      <Footer />
    </div>
  </section>
</div>
</>

)}
  </>
   
  );
};

export default Products;
